
.date-range-picker{
  z-index: 1000;
  position: absolute;
  right: 0;
  top: 20px;
  min-width: 700px;
}

[class*="materialui-react-daterange-picker-MuiIconButton-label-"]{
  color: white !important;
}

[class*="materialui-react-daterange-picker-MuiPaper-elevation0-"]{
  background-color: rgb(17, 17, 17) !important;
  color: white !important;
  border-radius: 16px !important;
}

 [class*="materialui-react-daterange-picker-makeStyles-highlighted-"]{
  background-color: rgb(86, 86, 86) !important;
  color: white !important;
}

[class*="materialui-react-daterange-picker-MuiPaper-elevation5-"]{
  background-color: rgb(86, 86, 86) !important;
  color: white !important;
  border-radius: 16px !important;
}

[class*="materialui-react-daterange-picker-MuiInputBase-input-"]{
  color: white !important;
}

[class*="materialui-react-daterange-picker-MuiSelect-nativeInput-"]{
  color: white !important;
}

[class*="materialui-react-daterange-picker-MuiTypography-root-"]{
  color: white !important;
}

[class*="materialui-react-daterange-picker-makeStyles-filled-"]{
  background-color: #81f7ff !important;
  color: rgb(0, 0, 0) !important;
}

[class*="materialui-react-daterange-picker-MuiSvgIcon-root-" ]{
  color: white !important;
}


[class*="materialui-react-daterange-picker-makeStyles-filled-"] span p {
  color: black !important;
}

[class*="materialui-react-daterange-picker-MuiTypography-colorTextSecondary-"]{
  color: rgb(125, 125, 125) !important;
}

[class*="materialui-react-daterange-picker-MuiTypography-caption-"]{
  color: white !important;
}

.dp-range-picker-container{
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  z-index: 0;
  position: fixed;
}