.udm-back{
    width: 100%;
    background-color: #0000007c;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.udm-msg{
    width: 50%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px #0000007c;
}