.schedule-table-cell{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.schedule-card{
    font-size: 1rem !important;
    font-family: 'Comic Sans MS', cursive, sans-serif !important;
}

.schedule-dailog{
    z-index: 1000;
    width: max-content;
    height: max-content;
    margin: auto;
    padding: 5px 15px; 
    border-radius: 10px;
    font-size: 1.3rem;
    color: white;
    backdrop-filter: blur(2px);
}