.table-sticky {
  width: 180px!important;
  max-width: 180px!important;
  min-width: 180px!important;
  background-color: #00000058;
  backdrop-filter: blur(10px);
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
}
.table-header-sticky {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
}
.table-sticky:first-child {
  left: 0;
  z-index: 1;
}

.shift-approved{
  position: relative;
  cursor: pointer;
  width: 180px;
  
}

.shift-data{
  border: 1px solid rgb(129 199 132);
  border-left: 5px solid rgb(129 199 132);
  border-radius: 5px;
  padding: 0;
}