body{
  margin: 0;
  font-family:  'Roboto', sans-serif;
}

.css-19kzrtu {
  padding: 0px 0px !important;
}

.MuiDropzoneArea-textContainer {
margin-top: 5rem!important;
}

