.MuiOutlinedInput-root.text {
    outline: none !important;
}

.MuiOutlinedInput-root.text:focus,
.MuiOutlinedInput-root.active {
    outline: 3px solid green !important;
    border-color: 3px green !important;
    color: brown;

}

.profile-button {
    /* background-color: #131313a2 !important;
    backdrop-filter: blur(10px); */
    color: white !important;
    width: fit-content !important;
}

.navbar-list span {
    font-size: 1rem;
    font-weight: 600;
}

.MuiListItemIcon-root {
    min-width: 40px !important;
}

.navbar-list div {
    margin-left: 2px;
}

/* .selectedNavItem{
    background-color: #4effca !important;
    color: rgb(0, 0, 0) !important;
}

.selectedNavItem svg{
    color: rgb(0, 0, 0) !important;
} */